// @ts-check
import React from "react"
import styled from "@emotion/styled"

import { BigPillButton } from "../components/button"
import Card from "../components/card"
import Layout from "../components/layout"
import Typography, { typographyStyles } from "../components/typography"
import colors, { hoverColors, TColor } from "../styles/colors"
import SEO from "../components/seo"
import CardPartners from "../components/cards/cardPartners"
import CardNoChitChat from "../components/cards/tools/cardNoChitChat"
import CardProductAssistant from "../components/cards/tools/cardProductAssistant"
import CardSeparator from "../components/cardSeparator"
import GirishAgarwal from "../components/cards/testimonials/GirishAgarwal"
import Link from "../components/link"
import { DesktopMediaQuery, MobileMediaQuery } from "../styles/constants"
import { CardFullWidth } from "../components/cards/cardFullWidth"
import { trackEvent } from "../utils/trackEvent"
import CardTestDrive from "../components/testdrive/cardTestDrive"
import { CardVideo } from "../components/cards/tools/cardVideo"
import { TestDrivePageAll } from "../components/testdrive/testDrivePageAll"
import ROI from "./roi-new"

const HeroSubtitle = styled(Typography)`
  ${MobileMediaQuery} {
    ${typographyStyles.bodySmall}
  }
  ${DesktopMediaQuery} {
    max-width: 800px;
  }
`

const HoverLink = styled(Link)<{ variant: TColor }>`
  display: inline-block;
  border-radius: 4px;
  padding: 0.2rem 0.3rem;
  color: ${colors.white};
  margin: 0 3px;
  background-color: ${p => colors[p.variant || "primary"]};
  text-decoration: none;

  &:hover {
    background-color: ${p => hoverColors[p.variant || "primary"]};
  }
`

const IndexPage = () => {
  if (process.env.GATSBY_PAGE_CONFIG === "testdrive")
    return <TestDrivePageAll />
  if (process.env.GATSBY_PAGE_CONFIG === "roi") return <ROI />

  return (
    <Layout>
      <SEO title="Home" />
      <Card variant="white" center>
        <Typography variant="hero" style={{ color: colors.primary }}>
          The product <br /> support platform
        </Typography>
        <HeroSubtitle
          variant="h6"
          style={{ marginTop: "3rem", lineHeight: "34px" }}
        >
          Mavenoid is the scalable support solution for hardware companies.
          <br />
          With
          <HoverLink variant="lightpurple" to="/product/self-service/">
            self-service
          </HoverLink>
          &nbsp;and&nbsp;
          <HoverLink variant="primary" to="/product/live-support/">
            live support
          </HoverLink>
          &nbsp;in&nbsp;one, you’re always ready to help your customers—from
          setup, to daily use, to troubleshooting.
          {/* </Trans> */}
        </HeroSubtitle>
        <BigPillButton
          as={Link}
          to="/request-demo/"
          style={{ marginTop: "3rem", width: "330px" }}
        >
          Request a demo
        </BigPillButton>
      </Card>
      <CardVideo />
      <CardTestDrive />
      <CardPartners title={undefined} variant="white" />
      <CardProductAssistant />
      <CardNoChitChat />
      <CardSeparator variant="lightgrey" />
      <CardFullWidth
        trackTryNow={() => {
          trackEvent({
            category: "sign_up_redirect",
            label: "homepage try free try now",
            siteSection: "homepage_try_free_for_30_days",
          })
        }}
        title="Experience support that really works"
        description={
          <div style={{ maxWidth: "440px" }}>
            Unlike generic support tools that focus on deflection, Mavenoid is
            all about solutions.
            <br />
            <br /> Schedule a demo and experience how Mavenoid meets customers
            with the right level of support to take them seamlessly from request
            to solution.
          </div>
        }
      />
      <GirishAgarwal
        trackCustomerStory={() => {
          trackEvent({
            category: "view_item",
            label: "homepage husqvarna customer story",
            siteSection: "homepage_testimonial",
          })
        }}
      />
    </Layout>
  )
}

export default IndexPage
